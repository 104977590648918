document.addEventListener("DOMContentLoaded", () => {
  loadData().then(() => {
    document.querySelector("video").play();
    removeLoader();
    readFile();
    dropdown();
    setHeaderGradient();
    ghostTextAnimation();
    aboutPieRotate();
    openMenu();
    titleAnimation();
    scrollToSection();
    sectionAnimation();
    formWp();
    isUploadFile();

    if (window.innerWidth >= 1280) {
      projectListAnimation();
      headerPosition();
    }
  });
});

function removeLoader() {
  let preloaderEl = document.getElementById("preloader");
  preloaderEl.classList.add("hidden");
  preloaderEl.classList.remove("visible");
  document.body.classList.remove("hidden");
}

function readFile() {
  const fileInput = document.querySelector("input[type=file]");
  const fileSpan = document.querySelector(".file-name .text");
  const inputWrapFile = document.querySelector(".input__wrap--file");

  fileInput.addEventListener("change", function () {
    let file = fileInput.files[0];
    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
      inputWrapFile.classList.add("active");
      fileSpan.innerText = file.name;
    };

    reader.onerror = function () {
      console.log(reader.error);
    };
  });
}

function dropdown() {
  let parent = document.querySelectorAll(".dropdown-link");
  parent.forEach((element) => {
    let collapsible_icon = element.querySelector("i");
    let collapsible_element = element.nextElementSibling;
    let collapsible_element_children = collapsible_element.children;
    let tl = new TimelineMax({
      reversed: true,
      paused: true,
    });

    tl.from(collapsible_element, 0.7, { className: "+=height-zero" }, "open");
    tl.staggerFrom(
      collapsible_element_children,
      0.7,
      {
        autoAlpha: 0,
        x: "-30%",
        ease: Expo.easeInOut,
      },
      0.2,
      "open+=.1",
    );

    if (window.innerWidth >= 1280) {
      element.parentElement.addEventListener("mouseover", () => {
        tl.reversed() ? tl.play() : tl.reverse();
        collapsible_icon.classList.add("active");
      });

      element.parentElement.addEventListener("mouseout", () => {
        tl.reversed() ? tl.play() : tl.reverse();
        collapsible_icon.classList.remove("active");
      });
    } else {
      element.addEventListener("click", () => {
        tl.reversed() ? tl.play() : tl.reverse();
        collapsible_icon.classList.toggle("active");
      });
    }
  });
}

function openMenu() {
  document.addEventListener("click", function (e) {
    const target = e.target;
    const menu = document.querySelector(".menu");
    const menuWrapper = document.querySelector(".menu__wrapper");
    const form = document.querySelector(".form__popup");
    const header = document.querySelector(".header");
    const headerBg = document.querySelector(".header__bg");
    const headerBurger = document.querySelectorAll(".header__burger")[1];

    if (target.closest(".header__burger") || target.closest(".form__link")) {
      e.preventDefault();
    }

    if (target.closest(".menu__navigation ul a")) {
      menu.classList.remove("active");
      headerBg.classList.remove("active");
      menuWrapper.classList.remove("active");
      headerBurger.classList.remove("active");
      document.body.classList.remove("hidden");
    }

    if (
      target.closest(".header__burger") &&
      !menu.classList.contains("active") &&
      !form.classList.contains("active")
    ) {
      document.body.style.paddingRight = `${getScrollbarWidth()}px`;
      header.style.paddingRight = `${getScrollbarWidth()}px`;

      let t1 = new TimelineMax();

      t1.staggerFrom(
        ".menu__navigation ul li",
        1.5,
        {
          x: "-20%",
          opacity: 0,
          ease: Expo.easeInOut,
          delay: 0.5,
        },
        0.6,
      );

      t1.fromTo(
        ".menu__link",
        1,
        {
          opacity: 0,
          x: "-20%",
          ease: Expo.easeInOut,
        },
        {
          opacity: 1,
          x: "0%",
        },
        "=-0.5",
      );

      t1.to(
        ".menu__link", 0, { onComplete:function(){
          this.target[0].querySelector('path').classList.add('link-animation')
      }});

      t1.staggerFrom(
        ".menu__social ul li",
        1,
        {
          x: "-20%",
          opacity: 0,
          ease: Expo.easeInOut,
          stagger: 0.2,
        },
        0.3,
        "=-1",
      );

      t1.staggerFrom(
        ".menu__language ul li",
        1,
        {
          x: "-20%",
          opacity: 0,
          ease: Expo.easeInOut,
          stagger: 0.3,
        },
        0.3,
        "=-0.7",
      );
    }

    if (window.innerWidth >= 1280) {
      if (target.closest(".header__burger")) {
        menu.classList.add("active");
        menuWrapper.classList.add("active");
        document.body.classList.add("hidden");
      }

      if (target.closest(".menu__close") || target.closest(".menu__wrapper")) {
        document.querySelector('.menu__link path').classList.remove("link-animation")
        menu.classList.remove("active");
        menuWrapper.classList.remove("active");
        document.body.classList.remove("hidden");
        document.body.style.paddingRight = null;
        header.style.paddingRight = null;
      }

      if (target.closest(".form__link")) {
        form.classList.add("active");
        console.log('ddd');
        setTimeout(linkAnimation,1000);
        document.body.style.paddingRight = `${getScrollbarWidth()}px`;
        header.style.paddingRight = `${getScrollbarWidth()}px`;

        if (menu.classList.contains("active")) {
          menu.classList.remove("active");
          menuWrapper.classList.remove("active");
        } else {
          document.body.classList.add("hidden");
        }
      }

      if (
        target === document.querySelector(".form__popup-body") ||
        target.closest(".form__close")
      ) {
        form.classList.remove("active");
        document.body.style.paddingRight = null;
        header.style.paddingRight = null;
        if (menu.classList.contains("active")) {
          return;
        } else {
          document.body.classList.remove("hidden");
        }
      }
    } else {
      if (target.closest(".header__burger") && !form.classList.contains("active")) {
        document.querySelector('.menu__link path').classList.remove("link-animation")
        menu.classList.toggle("active");
        headerBg.classList.toggle("active");
        headerBurger.classList.toggle("active");
        document.body.classList.toggle("hidden");
      }

      if (target.closest(".form__link")) {
        form.classList.add("active");
        setTimeout(linkAnimation,1000);
        headerBg.classList.add("active");
        headerBurger.classList.add("active");
        document.body.classList.add("hidden");
      }

      if (target.closest(".header__burger") && !menu.classList.contains("active")) {
        form.classList.remove("active");
        headerBg.classList.remove("active");
        headerBurger.classList.remove("active");
        document.body.classList.remove("hidden");
      }

      if (target.closest(".header__burger") && menu.classList.contains("active")) {
        form.classList.remove("active");
      }
    }
  });
}

function getBodyScrollTop() {
  return (
    self.pageYOffset ||
    (document.documentElement && document.documentElement.scrollTop) ||
    (document.body && document.body.scrollTop)
  );
}

function linkAnimation(){
  form.querySelector('.form__submit').querySelector('path').classList.add('link-animation')
}

function setHeaderGradient() {
  const headerBg = document.querySelector(".header__bg");
  const bannerVideoBg = document.querySelector(".banner__video-bg");
  headerBg.style.top = `-${getBodyScrollTop()}px`;
  headerBg.style.height = `${document.body.clientHeight}px`;
  bannerVideoBg.style.height = `${document.body.clientHeight}px`;

  document.addEventListener("scroll", (e) => {
    headerBg.style.height = `${document.body.clientHeight}px`;
    headerBg.style.top = `-${getBodyScrollTop()}px`;
    bannerVideoBg.style.height = `${document.body.clientHeight}px`;
  });
}

function ghostTextAnimation() {
  const ghostText = document.querySelectorAll(".services__ghost-text");

  ghostText.forEach((item, index) => {
    const randomNum = Math.random() * 0.3 + 0.15;
    document.addEventListener("scroll", (e) => {
      if (index % 2 === 0) {
        item.style.transform = `translateX(-${getBodyScrollTop() * randomNum}px)`;
      } else {
        item.style.transform = `translateX(${getBodyScrollTop() * randomNum}px)`;
      }
    });
  });
}

function aboutPieRotate() {
  const pie = document.querySelector(".about__pie svg");
  const piece = document.querySelectorAll(".about__pie .piece");
  const content = document.querySelector(".about__content");
  const items = document.querySelectorAll(".about__content .item");
  let t1 = new TimelineMax();

  const maxHeightItem = Array.from(items).reduce((p, v) => {
    return p.offsetHeight > v.offsetHeight ? p.offsetHeight : v.offsetHeight;
  });

  content.style.height = `${maxHeightItem + 30}px`;

  piece.forEach((item, index) => {
    item.addEventListener("click", function (e) {
      piece.forEach((i) => i.classList.remove("active"));
      items.forEach((j) => j.classList.remove("active"));

      setTimeout(() => {
        items[index].classList.add("active");
      }, 1000);
      item.classList.add("active");
      // pie.style.transform = `scale(1.5) rotate(${index * 60 + 90}deg)`;

      if (window.innerWidth >= 1280) {
        t1.to(pie, 2, {
          rotation: index * 60 + 90,
          ease: Expo.easeInOut,
        });
      } else {
        t1.to(pie, 2, {
          rotation: index * 60,
          ease: Expo.easeInOut,
        });
      }

      // t1.to(
      //   items[i],
      //   2,
      //   {
      //     className: "-=active",
      //     ease: Expo.easeInOut,
      //   },
      //   "=-2",
      // );
    });
  });

  // setTimeout(function run() {
  //   t1.to(pie, 2, {
  //     rotation: rotateDeg,
  //     ease: Expo.easeInOut,
  //   });
  //   t1.to(
  //     piece[i],
  //     2,
  //     {
  //       className: "-=active",
  //       ease: Expo.easeInOut,
  //     },
  //     "=-2",
  //   );
  //   t1.to(
  //     items[i],
  //     2,
  //     {
  //       className: "-=active",
  //       ease: Expo.easeInOut,
  //     },
  //     "=-2",
  //   );

  //   if (i > 4) {
  //     i = 0;
  //     t1.to(piece[i], 2, {
  //       className: "+=active",
  //       ease: Expo.easeInOut,
  //     });
  //     t1.to(
  //       items[i],
  //       2,
  //       {
  //         className: "+=active",
  //         ease: Expo.easeInOut,
  //       },
  //       "=-2",
  //     );
  //   } else {
  //     t1.to(
  //       piece[i + 1],
  //       2,
  //       {
  //         className: "+=active",
  //         ease: Expo.easeInOut,
  //       },
  //       "=-0.5",
  //     );
  //     t1.to(
  //       items[i + 1],
  //       2,
  //       {
  //         className: "+=active",
  //         ease: Expo.easeInOut,
  //       },
  //       "=-2",
  //     );
  //     i++;
  //   }

  //   // pie.style.transform = `rotate(${rotateDeg}deg)`;
  //   rotateDeg += 360 / 6;
  //   setTimeout(run, 6000);
  // }, 6000);
}

function loadData() {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 3500);
  });
}

function customCursor() {
  const cursor = document.querySelector(".cursor");
  const follower = document.querySelector(".cursor-follower");

  let posX = 0;
  let posY = 0;

  let mouseX = 0;
  let mouseY = 0;

  TweenMax.to({}, 0.016, {
    repeat: -1,
    onRepeat: function () {
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;

      TweenMax.set(follower, {
        css: {
          left: posX - 22,
          top: posY - 22,
        },
      });

      TweenMax.set(cursor, {
        css: {
          left: mouseX,
          top: mouseY,
        },
      });
    },
  });

  document.addEventListener("mousemove", function (e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
  });

  // $(".link").on("mouseenter", function () {
  //   cursor.addClass("active");
  //   follower.addClass("active");
  // });
  // $(".link").on("mouseleave", function () {
  //   cursor.removeClass("active");
  //   follower.removeClass("active");
  // });
}

function titleAnimation() {
  let t1 = new TimelineMax();

  t1.fromTo(
    ".banner__title",
    1,
    {
      opacity: 0,
      x: "-60%",
      ease: Expo.easeInOut,
    },
    {
      opacity: 1,
      x: "0%",
    },
    "=+1",
  );

  t1.fromTo(
    ".banner__title span",
    1,
    {
      opacity: 0,
      x: "-20%",
      ease: Expo.easeInOut,
    },
    {
      opacity: 1,
      x: "0%",
    },
  );

  if (window.innerWidth >= 1280) {
    t1.fromTo(
      ".banner__link",
      1,
      {
        opacity: 0,
        x: "-20%",
        ease: Expo.easeInOut,
      },
      {
        opacity: 1,
        x: "0%",
      },
      "=-0.5",
    );

    t1.to(
      ".banner__link", 0, { onComplete:function(){
        this.target[0].querySelector('path').classList.add('link-animation')
    }});

    t1.staggerFrom(
      ".banner__description p",
      2,
      {
        x: "-20%",
        opacity: 0,
        ease: Expo.easeInOut,
        stagger: 0.2,
      },
      0.4,
      "=-1",
    );
  } else {
    t1.staggerFrom(
      ".banner__description p",
      2,
      {
        x: "-20%",
        opacity: 0,
        ease: Expo.easeInOut,
        stagger: 0.2,
      },
      0.4,
      "=-1",
    );

    t1.fromTo(
      ".banner__link",
      1,
      {
        opacity: 0,
        x: "-20%",
        ease: Expo.easeInOut,
      },
      {
        opacity: 1,
        x: "0%",
      },
      "=-0.5",
    );

    t1.to(
      ".banner__link", 0, { onComplete:function(){
        this.target[1].querySelector('path').classList.add('link-animation')
    }});
  }
}

function projectListAnimation() {
  const projects = document.querySelector(".projects");
  const projectsList = document.querySelectorAll(".projects__item");

  projectsList.forEach((item) => {
    const randomNum = Math.random() * 1 + 0.2;

    scrollItem();
    item.addEventListener("mouseover", mouseOverListener);
    item.addEventListener("mouseout", mouseOutListener);
    item.addEventListener("mousemove", mouseMoveListener);
    document.addEventListener("scroll", scrollItem);

    function mouseOverListener() {
      projectsList.forEach((item) => {
        this.classList.add("active");
        if (item !== this) {
          item.classList.add("hover");
        }
      });
    }

    function mouseOutListener() {
      projectsList.forEach((item) => {
        this.classList.remove("active");
        if (item !== this) {
          item.classList.remove("hover");
        }
      });
    }

    function mouseMoveListener(e) {
      const text = this.querySelector(".text-content");
      const targetCoords = this.getBoundingClientRect();
      const xPos = e.clientX - targetCoords.left;
      const yPos = e.clientY - targetCoords.top;

      if (targetCoords.width < xPos || targetCoords.height < yPos) {
        mouseOutListener.call(this);
        text.style.cssText = `left: 15px; bottom: 20px`;
      }

      text.style.cssText = `left: ${xPos + 15}px; top: ${yPos - 10}px`;
    }

    function scrollItem() {
      const projectsPosition = projects.getBoundingClientRect().top;

      if (projectsPosition - window.innerHeight <= 0) {
        item.style.transform = `translateY(${projectsPosition * randomNum}px)`;
      }
    }
  });
}

function scrollToSection() {
  const sections = document.querySelectorAll("#services, #about, #projects, #contacts");
  const menuNavigation = document.querySelectorAll(".menu__navigation ul a");
  let aside = 100;

  function handleButtonClick(y) {
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  menuNavigation.forEach((item, index) => {
    const y = sections[index].getBoundingClientRect().top + window.pageYOffset - aside;
    item.addEventListener("click", function (e) {
      e.preventDefault();
      handleButtonClick(y);
      document.body.style.paddingRight = 0;
    });
  });

  document.addEventListener("click", function (e) {
    const target = e.target;

    if (target.closest(".banner__link")) {
      e.preventDefault();

      const y =
        document.querySelector("#projects").getBoundingClientRect().top +
        window.pageYOffset -
        aside;
      handleButtonClick(y);
    }
  });
}

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

function sectionAnimation() {
  const servicesObserver = new IntersectionObserver((entries, service) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const item = entry.target;
        let t1 = new TimelineMax();

        t1.staggerFrom(
          item.querySelectorAll(".services__item"),
          3,
          {
            cycle: {
              x: function (index) {
                if (index % 2 === 0) {
                  return "-20%";
                } else {
                  return "+20%";
                }
              },
            },
            opacity: 0,
            ease: Expo.easeOut,
          },
          0.5,
          "=+0.5",
        );

        service.unobserve(item);
      }
    });
  });

  const services = document.querySelector(".services__list");
  servicesObserver.observe(services);

  const aboutObserver = new IntersectionObserver((entries, aboute) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const item = entry.target;
        let t1 = new TimelineMax();

        t1.from(
          item.querySelector(".about__pie"),
          1,
          {
            y: "+20%",
            opacity: 0,
            ease: Expo.easeOut,
          },
          "=+0.5",
        );

        t1.from(
          item.querySelector(".about__content"),
          1,
          {
            y: "+20%",
            opacity: 0,
            ease: Expo.easeOut,
          },
          "=-0.5",
        );

        aboute.unobserve(item);
      }
    });
  });

  const about = document.querySelector(".about");
  aboutObserver.observe(about);

  const projectsObserver = new IntersectionObserver((entries, project) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const item = entry.target;
        let t1 = new TimelineMax();

        t1.from(
          item.querySelector(".projects__title"),
          1.5,
          {
            x: "+20%",
            opacity: 0,
            ease: Expo.easeOut,
          },
          "=+0.5",
        );

        t1.staggerFrom(
          item.querySelectorAll(".projects__item"),
          3,
          {
            opacity: 0,
            ease: Expo.easeOut,
          },
          0.5,
          "=-1",
        );

        project.unobserve(item);
      }
    });
  });

  const projects = document.querySelector(".projects");
  projectsObserver.observe(projects);

  const contactsObserver = new IntersectionObserver((entries, contact) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const item = entry.target;
        let t1 = new TimelineMax();

        t1.from(
          item.querySelector(".contacts__superscription"),
          1.5,
          {
            x: "+20%",
            opacity: 0,
            ease: Expo.easeOut,
          },
          "=+0.5",
        );

        t1.from(
          item.querySelector(".contacts__description"),
          1.5,
          {
            x: "+20%",
            opacity: 0,
            ease: Expo.easeOut,
          },
          "=-1",
        );

        if (window.innerWidth >= 1280) {
          t1.from(
            item.querySelector(".contacts__contact-link"),
            1.5,
            {
              x: "+20%",
              opacity: 0,
              ease: Expo.easeOut,
            },
            "=-1",
          );

          t1.to(
            ".contacts__contact-link", 0, { onComplete:function(){
              this.target[0].querySelector('path').classList.add('link-animation')
          }});

          t1.staggerFrom(
            item.querySelectorAll(".contacts__item"),
            3,
            {
              cycle: {
                x: function (index) {
                  if (index % 2 === 0) {
                    return "-20%";
                  } else {
                    return "+20%";
                  }
                },
              },
              opacity: 0,
              ease: Expo.easeOut,
            },
            0.5,
            "=-1",
          );
        } else {
          t1.staggerFrom(
            item.querySelectorAll(".contacts__item"),
            3,
            {
              cycle: {
                x: function (index) {
                  if (index % 2 === 0) {
                    return "-20%";
                  } else {
                    return "+20%";
                  }
                },
              },
              opacity: 0,
              ease: Expo.easeOut,
            },
            0.5,
            "=-1",
          );

          t1.from(
            item.querySelector(".contacts__contact-link"),
            1.5,
            {
              x: "+20%",
              opacity: 0,
              ease: Expo.easeOut,
            },
            "=-2.5",
          );

          t1.to(
            ".contacts__contact-link", 0, { onComplete:function(){
              this.target[0].querySelector('path').classList.add('link-animation')
          }});
        }

        contact.unobserve(item);
      }
    });
  });

  const contacts = document.querySelector(".contacts");
  contactsObserver.observe(contacts);
}

function headerPosition() {
  const headerLogoImage = document.querySelector(".header__logo img");
  const sidebar = document.querySelector(".sidebar");
  const title = document.querySelector(".banner__title-wrap");
  const text = document.querySelector(".banner__content");
  const phoneWrap = document.querySelector(".phone__wrap");

  const footerLogoImage = document.querySelector('.footer__logo img');
  const footerContact = document.querySelector('.footer__contacts-wrap')

  listener();
  window.onresize = listener;

  function listener() {
    if (window.innerWidth >= 1280) {
      headerLogoImage.style.left = `${title.getBoundingClientRect().left - sidebar.clientWidth}px`;
      phoneWrap.style.right = `${window.innerWidth - text.getBoundingClientRect().right}px`;

      footerLogoImage.style.left = `${title.getBoundingClientRect().left - sidebar.clientWidth}px`;
      footerContact.style.right = `${window.innerWidth - text.getBoundingClientRect().right}px`;
    } else {
      return;
    }
  }
}


function formWp() {
  const form = document.querySelector(".wpcf7"),
        mailsent = document.querySelector(".form__mailsent"),
        formPopup = document.querySelector(".form__popup"),
        btnCloseMailsent = document.querySelector(".form__mailsent-close"),
        uploadTextHover = document.querySelector('.upload-text-hover').innerHTML,
        upload = document.querySelector('.upload').querySelector('.text-content');

  upload.innerHTML = uploadTextHover;

  form.addEventListener("wpcf7mailsent",function () {
    formPopup.classList.remove("active");
    mailsent.classList.add("active");
    form.querySelector('.input__wrap--file').classList.remove("active");
  }, false);
  
  btnCloseMailsent.addEventListener("click", function (e) {
    e.stopPropagation();
    mailsent.classList.remove("active");
    document.body.classList.remove("hidden");
  }, false);
    
  document.addEventListener("click", function (e) {
    const target = e.target,
          its_mailsent = target == mailsent || mailsent.contains(target),
          its_btnCloseMailsent = target == btnCloseMailsent,
          mailsent_is_active = mailsent.classList.contains("active");
    
    if (!its_mailsent && !its_btnCloseMailsent && mailsent_is_active) {
      mailsent.classList.remove("active");
      document.body.classList.remove("hidden");
    }
  }, false);
  
};

function isUploadFile() {
  const formBox = document.querySelector(".form__popup"),
        inputFile = formBox.querySelector('input[type="file"]'),
        uploadTitle = formBox.querySelector('.upload-title span'),
        uploadTitleText = formBox.querySelector('.upload-title-text').innerHTML;

  inputFile.addEventListener('change', function(){
    if( this.value ){
      uploadTitle.innerHTML = uploadTitleText
    }
  });
}


